$enable-dark-mode: false;

$primary: #D51E27;
$primary-dark: #b51a21;
$secondary: #293133;
$light: #fff;
$body-color: #293133;

$grid-gutter-width: 2.5rem;

$btn-font-family: var(--font-default-bold);
$btn-padding-y: calc(12rem/16);
$btn-padding-x: calc(18rem/16);
$btn-font-size: calc(16rem/16);
$btn-border-radius: calc(8rem/16);
$btn-line-height: 1.1;
$btn-close-opacity: .7;
$btn-close-hover-opacity: 1;
$nav-tabs-border-width: 0;

// BREADCRUMB
$breadcrumb-divider: var(--icon-arrow-right);
$breadcrumb-margin-bottom: 0;
$breadcrumb-divider-color:var(--color-secondary);
$breadcrumb-active-color:var(--color-primary);
$breadcrumb-item-padding-x:0.5rem;
$breadcrumb-font-size:0.8rem;

// MODAL
$modal-header-border-color: var(--color-light-grey);
$modal-header-border-width: .25rem;
$modal-header-padding-y:1.5rem;
$modal-header-padding-x:2.5rem;
$zindex-modal: 1100;

$modal-backdrop-opacity:.3;

$aspect-ratios: (
    "1x1": 100%,
    "4x3": calc(3 / 4 * 100%),
    "16x9": calc(9 / 16 * 100%),
    "3x4": calc(4 / 3 * 100%),
    "3x2": calc(2 / 3 * 100%),
    "3x1": calc(1 / 3 * 100%),
    "10x4": calc(4 / 10 * 100%),
    "2x1": calc(1 / 2 * 100%),
    "map": calc(45 / 100 * 100%),
);

// these ratios override the ratios above for mobile devices
$aspect-ratios-xs: (
    "map": calc(4 / 3 * 100%),
);

// form fun
$input-btn-padding-y:               0.80rem;
$input-btn-padding-x:               1.125rem;
$input-btn-font-family:             var(--font-default);
$input-btn-font-size:               calc(16rem/16);
$input-btn-line-height:             1;
$input-focus-color:                 var(--color-secondary);
$input-focus-border-color:          rgba(41,49,51,0.2);
$input-focus-box-shadow:            0 0 0 0.1rem rgba(41,49,51,0.2);
$input-border-color:                rgba(41,49,51,0.2);
$input-border-radius:               calc(8rem/16);
$input-btn-border-width:            1px;

$input-height:                      calc(138rem/16);

$form-check-input-width:            1.875rem;
$form-check-min-height:             1.875rem;
$form-check-padding-start:          2.5rem;
$form-check-input-checked-bg-color: var(--color-primary);
$form-check-input-focus-border:     var(--color-secondary);
$form-check-input-border:           1px solid rgba(41,49,51,0.2);
$form-check-input-focus-box-shadow: none;

$form-select-padding-y:             calc(13rem/16);
$form-select-line-height:           1.2;
$form-select-focus-box-shadow:      0 0 0 0.1rem rgba(41,49,51,0.2);

$form-label-margin-bottom:          calc(10rem/16);

$form-valid-border-color:           rgba(41,49,51,0.2);
$form-valid-color:                  rgba(41,49,51,0.2);

// Pagination
$pagination-font-size:              calc(18rem/16);
$pagination-color:                  var(--color-dark-grey);
$pagination-bg:                     transparent;
$pagination-border-radius:          0;
$pagination-border-width:           0;
//$pagination-margin-start:           ($pagination-border-width * -1) !default;
$pagination-focus-color:            var(--color-secondary) !default;
$pagination-focus-bg:               transparent;
$pagination-focus-box-shadow:       none;
//$pagination-focus-outline:          0 !default;
$pagination-hover-color:            var(--color-secondary) !default;
$pagination-hover-bg:               transparent;
//$pagination-hover-border-color:     $gray-300 !default;

$pagination-active-color:           var(--color-secondary);
$pagination-active-bg:              transparent;
//$pagination-active-border-color:    $pagination-active-bg !default;
$pagination-disabled-color:         var(--color-light-grey) !default;
$pagination-disabled-bg:            transparent;

// Popover
$popover-max-width:                 455px;
$popover-font-size:                 calc(14rem/16);
$popover-border-width:              0;
$popover-border-color:              transparent;
$popover-border-radius:             8px;
$popover-inner-border-radius:       0;

$popover-header-color:              var(--color-primary);
$popover-header-bg:                 #fff;
$popover-header-padding-y:          calc(1rem/16);
$popover-header-padding-x:          calc(1rem/16);

$popover-body-padding-y:            calc(60rem/16);
$popover-body-padding-x:            calc(47rem/16);

$popover-arrow-width:               0;
$popover-arrow-height:              0;
$popover-arrow-color:               rgba(0,0,0,0);
$zindex-popover:                    1080;