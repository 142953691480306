@import './style/variables.scss';
@import './libs/bootstrap/scss/bootstrap.scss';
@import 'blaze-slider/dist/blaze';

@import '@elements/lightbox/scss/lightbox';
@import "lightgallery/scss/lg-video";

@import "flatpickr/dist/flatpickr";
@import "@elements/datepicker/scss/datepicker";

@import '@elements/range-slider/scss/range-slider';
@import "@elements/alert-notification/scss/alert-notification";

@import './libs/autocomplete-base.scss';